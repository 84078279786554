import React from 'react'
import PropTypes from 'prop-types'
import Router from 'next/router'
import NProgress from 'nprogress'
import { ThemeProvider } from 'styled-components'
import { LogoJsonLd } from 'next-seo'
import theme from '../../../theme/vars'
import Head from './Head'
import Header from './Header'
import Footer from './Footer'
import layoutStyles from './index.styles'
import LeftMenu from './Header/LeftMenu'
import Basket from '../Modules/Basket/Basket'
import { useSync, useStorage, useLive } from '../../utils/Storken'
import { setCost } from '../../services/CustomerActions'
import {emptyBasketControl, tereYagBasketControl, userDataControl} from '../../services/CustomerOrderHelper'
import { pageview } from '../../services/pixel'

const { useEffect } = React


const Layout = ({ children, ...props }) => {
  const [storageBasket, setStorageBasket] = useStorage('baskets')
  const [products] = useLive('products')
  const [syncbasket, setSyncBasket] = useSync('basket')
  const [firstStart, setFirstStart] = useSync('firstStart')
  const [order] = useSync('order')
  const GlobalStyles = layoutStyles(theme)
  useEffect(() => {
    userDataControl(props, order)
  }, [order, props])
  useEffect(() => {
    emptyBasketControl(props, storageBasket)
    tereYagBasketControl(props, storageBasket)
  }, [props])


  useEffect(() => {
    // eslint-disable-next-line no-sequences
    if (firstStart && syncbasket.length === 0 && products.length > 0) {
      setFirstStart(false)
      // eslint-disable-next-line array-callback-return,consistent-return
      const filtered = storageBasket.map((e) => {
        const select = products.find(({ _id }) => e?._id === _id)
        const selectedPack = select?.sizeprice?.find(({ _id }) => e?.packsize?._id === _id)
        const prodObj = { ...select }
        if (prodObj && selectedPack && selectedPack.stock > 0) {
          prodObj.packsize = selectedPack
          prodObj.quantity = e?.quantity
          prodObj.cost = prodObj?.quantity * prodObj?.packsize?.price
          const diff = prodObj?.quantity - selectedPack?.stock
          if (select?.stocked && diff > 0) {
            prodObj.quantity -= diff
            prodObj.cost = prodObj?.quantity * prodObj?.packsize?.price
          }
          return prodObj
        }
      }).filter(Boolean)
      setSyncBasket(filtered)
      setStorageBasket(filtered)
      setCost()
    }

    Router.events.on('routeChangeStart', () => NProgress.start())
    Router.events.on('routeChangeComplete', () => NProgress.done())
    Router.events.on('routeChangeError', () => NProgress.done())
  }, [firstStart, setFirstStart, storageBasket, syncbasket.length, products, setSyncBasket, setStorageBasket, syncbasket])

  Router.onRouteChangeComplete = () => {
    pageview()
    window.scroll({
      top: 0,
      left: 0
    })
  }

  return (
    <main>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <Head />
          <noscript
            dangerouslySetInnerHTML={{
              __html: '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K6S2ZLS" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
            }}
          />
          <GlobalStyles />
          <div className='app-body'>
            <LogoJsonLd
              logo='https://svgl.s3.eu-west-3.amazonaws.com/kg-logo.jpg'
              url='https://www.koydengelsinn.com'
            />
            <Header {...props} />
            <LeftMenu {...props} />

            {
              // eslint-disable-next-line react/prop-types,react/destructuring-assignment
              props.url.pathname === '/' || props.url.pathname === '/public/product'
                ? <Basket />
                : null
            }
            {
              /*
              // eslint-disable-next-line react/prop-types,react/destructuring-assignment
              props.url.pathname === '/' || props.url.pathname === '/public/product' || props.url.pathname === 'yenisiparis'
                ? (
                  <MessengerCustomerChat
                    pageId='2280357792245166'
                    appId='226399598996562'
                  />
                )
                : null
               */
            }
            {children}
            <Footer />
          </div>
        </React.Fragment>
      </ThemeProvider>
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object
}

export default Layout
