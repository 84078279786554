import React, { useEffect, useState } from 'react'
import { Icon, Placeholder } from 'rsuite'
import { Image } from 'semantic-ui-react'
import { QuantityComponent } from '../Main/QuantityComponent'


const { Paragraph } = Placeholder

const BasketElements = ({ basket, removeToInBasket }) => {
  useEffect(() => {
  }, [])
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [wait, setWait] = useState({ id: '' })

  const preRemoveToBasket = (_id, size) => {
    setWait({ id:_id })
    setTimeout(() => {
      removeToInBasket(_id, size)
      setWait({ id:'' })
    }, 300)
  }

  return basket?.map(e => (
    <div key={e?._id}>
      {
        wait.id === e._id ? <Paragraph style={{ marginTop: 30, marginLeft:20 }} rows={4} graph='square' active />
          : (
            <div key={e._id} className='sepet_eleman'>
              <QuantityComponent
                iconClass='sepet_count'
                divClass='counter'
                size={e?.packsize?.size}
                _id={e._id}
                count={e?.quantity}
              />
              <Image size='tiny' src={e?.photos[0]?.data_url} className='sepet_gorsel' alt='' />
              <div className='sepet_detay'>
                <span className='sepet_urun_ad'>
                  {e?.name}
                </span>
                <span className='sepet_urun_fiyat'>
                  {e?.packsize?.price} ₺
                </span>
                <span className='sepet_urun_adet'>
                  {e.quantity} X {e.packsize.size}
                </span>
              </div>
              <span className='sepet_urun_ucret'>
                {e.cost}₺
              </span>
              <span className='sepet_urun_remove'>
                {/* eslint-disable-next-line no-underscore-dangle */}
                <Icon size='lg' onClick={() => preRemoveToBasket(e?._id, e?.packsize?.size)} icon='close' />
              </span>
            </div>
          )
      }
    </div>
  ))
}
export default BasketElements
