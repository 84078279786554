import React, { useEffect, useState } from 'react'
import { Drawer, Icon, Progress } from 'rsuite'
import { Link } from '../../../../server/routes'
import { getSync, useLive, useSession, useSync } from '../../../utils/Storken'
import BasketElements from './BasketElements'
import { removeToInBasket, setQuantity, setCost, getMinCargoCost } from '../../../services/CustomerActions'
import { event } from '../../../services/EventActions'
import { customPixelEvent } from '../../../services/pixel'
import useWindowSize from '../../../hoc/useWindowDimensions'


const Basket = () => {
  const [basket] = useSync('basket')
  const [basketShow, setBasketShow] = useSync('openbasket')
  const [placement, setPlacement] = useSync('placement')
  const [cost] = useSync('cost')
  const [, setOnLeft] = useSync('onleft')
  const [, setSessionBasket] = useSession('baskets')
  const [, setCosts] = useSession('costs')

  function yuzdeHesapla(ilkParametre, ikinciParametre) {
    if (ilkParametre === 0) {
      return 0
    }
    const yuzde = (ikinciParametre / ilkParametre) * 100
    return yuzde
  }

  useEffect(() => {
    setCost()
    setCosts(cost)
  },
  [cost, setCosts])

  useEffect(() => {
    // console.log('paket', categories.find( ({name}) => name==='Paketler') )
  }, [])

  // eslint-disable-next-line no-shadow
  const onBasketOpen = (placement) => {
    setBasketShow(true)
    setPlacement(placement)
  }

  const DesktopSepet = () => (
    // eslint-disable-next-line react/button-has-type
    <button onClick={() => onBasketOpen('right')} className='sepet_gösterici'>
      <span className='sepet_bilgileri'>
        <span className='sepet_göster_icon'> <Icon size='lg' icon='shopping-basket' /></span>
        {basket.length} ürün
      </span>
      <span className='sepet_goster_fiyat'>
        {cost} ₺
      </span>
    </button>
  )
  const MobileSepet = () => (
    // eslint-disable-next-line react/button-has-type
    <button
      onClick={() => onBasketOpen('bottom')}
      className='mobile_sepet_gösterici'
    >
      <span style={{ color:'white', marginRight:'10px' }}><Icon size='lg' icon='shopping-basket' />
      </span>
      <span className='text-light'>{basket.length} ürün</span>
      <span className='mobile_sepet_ucret'>{cost} ₺</span>
    </button>
  )

  return (
    <>
      <DesktopSepet />
      <MobileSepet />
      <Drawer
        className='sepet_gosterici'
        size={useWindowSize()?.width > 991 ? 'xs' : basket?.length > 1 ? 'lg' : 'md'}
        show={basketShow}
        onHide={() => setBasketShow(!basketShow)}
        placement={placement}
      >
        <Drawer.Header>
          <div className='sepet_title'>
            <div className='sepet_title_eleman'>
              <span style={{ marginRight:'10px' }}> <Icon icon='shopping-basket' /></span>
              <span>{basket.length} ürün</span>
              <Progress.Line
                percent={yuzdeHesapla(getMinCargoCost(), cost)}
                showInfo={false}
                status={`${yuzdeHesapla(getMinCargoCost(), cost) >= 100 ? 'success' : ''}`}
              />
              {yuzdeHesapla(getMinCargoCost(), cost) < 100 ? (
                <span>Bedava kargo için {getMinCargoCost()}TL'ye ulaşın</span>
              )
                : (<span><Icon icon='check' style={{ marginRight:'4px' }} />Kargonuz ücretisizdir</span>)
              }
            </div>
            <div className='sepet_title_eleman' />
          </div>
        </Drawer.Header>
        <Drawer.Body className='sepet_body'>
          <div className='sepet_kapsam'>
            <div className='sepet_duzen'>
              <div className='sepet_yerlesimi'>
                <BasketElements
                  removeToInBasket={removeToInBasket}
                  setQuantity={setQuantity}
                  basket={basket}
                  cost={cost}
                />
              </div>
            </div>
          </div>
          {
            // eslint-disable-next-line react/prop-types
            basket.length > 0
              ? (
                <div className='tamamla'>
                  {/* eslint-disable-next-line react/button-has-type */}
                  <button
                    onClick={() => {
                      setSessionBasket(getSync('basket'))
                      setOnLeft(false)
                      event('begin_checkout', getSync('basket'), getSync('cost'))
                      customPixelEvent('InitiateCheckout', getSync('basket'), getSync('cost'))
                    }}
                    className='tamamla_buton'
                  >
                    <Link href='/yenisiparis/#bilgilerim'>Siparişi Tamamla</Link>
                    <span className='tamamla_ucret'>
                      {cost}₺
                    </span>
                  </button>
                </div>
              )
              : null
          }
        </Drawer.Body>
      </Drawer>
    </>
  )
}
export default Basket
