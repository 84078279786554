import React, { useState } from 'react'
import { Icon, Loader } from 'rsuite'
import { setQuantity } from '../../../services/CustomerActions'

export const QuantityComponent = ({ _id, size, count, divClass, iconClass }) => {
  const [wait, setWait] = useState(false)
  // eslint-disable-next-line no-shadow
  const preSetQuantity = (up, _id, size) => {
    setWait(true)
    setTimeout(() => {
      setWait(false)
      up
        ? setQuantity(1, _id, size)
        : setQuantity(-1, _id, size)
    }, 75)
  }
  return (
    <div className={divClass}>
      <Icon className={iconClass} icon='data-decrease' onClick={() => preSetQuantity(false, _id, size)} />
      <span>{wait ? <Loader speed='fast' /> : count}</span>
      <Icon className={iconClass} icon='plus' onClick={() => preSetQuantity(true, _id, size)} />
    </div>
  )
}
